#menuStore{


	#location-search{

		.input-group{

			> .form-control{
				height: 46px;
				padding: 10px;
			}//form-control

			.input-group-prepend span,
			.input-group-append button{
				padding: 10px 20px;
			}

		}//input-group

	}//location-search





	.box-one{
		padding: 10px 20px;

		h1{
			color: var(--color-primary);
			font-size: 1.3rem;
			font-weight: 600;
		}//h1

		.rating{
			display: none !important;
		}//rating

		dl dd{
			font-size: .9rem;
			line-height: 1.3;
		}

	}//box-one




	#local-box-two{
		background: #f7f7f7;
		font-size: .8rem;
		line-height: 1.3;
		padding: 10px 20px;
		border-radius: 6px;


		.text-success{
			font-family: 'Dosis', sans-serif;
			font-size: .8rem;
			line-height: 1;
			text-transform: uppercase;
		}//text-success

	}//local-box-two



	#nav-tabs{
		.nav-item{
			color: var(--color-primary);
			font-family: 'Dosis', sans-serif;
			font-size: .9rem;
			line-height: 1;
			font-weight: 600;
			letter-spacing: .5px;
			text-transform: uppercase;


			&.active{
				font-weight: 800;
			}//active

		}//nav-item
	}//nav-tabs




	.menu-item{

		.menu-name{
			color: var(--color-primary);
			font-family: 'Dosis', sans-serif;
			font-size: 1rem;
			font-weight: 600;
		}


		.menu-desc{
			font-family: 'Dosis', sans-serif;
			font-size: .9rem;
			font-weight: 500;
		}

		.menu-price{
			color: var(--color-primary);
			font-family: 'Dosis', sans-serif;
			font-weight: 700;
		}

	}//menu-item


	.menu-thumb{
		height: 65px;
		border-radius: 6px;
		overflow: hidden;

		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}


}//menuStore